import AboutUsDocument from './sheHarbour.pdf';

const AboutUs = () => {
    return (
        <>
            <section className="about__us container grid gap2-tb wdth80 txt__secondary mg-block-2">
                <div className="content wrapper grid gap2 gap2-tb gap2-mb pd-3 pd-1-tb pd-1-mb">
                    <div className="about__us__content wrapper grid gap-small">
                        <h3 className="fw500 capitalize txt__primary">
                            About Us
                        </h3>
                        <p className="normal">
                            SHE HARBOUR FOUNDATION (SHF) is a nonprofit making organization founded and registered in Uganda in July 2020. The Foundation aims at providing Relief and Empowerment to the Vulnerable Girl Child across Uganda both in urban and rural place. Among others, the Foundation seeks to advocate for awareness of freedom of expression and other human rights of the girl child, advocacy for compulsory stay in school of the girl child through focusing on elimination of the many factors that keep them out of school.....
                        </p>
                        <a href={`${AboutUsDocument}`} download="sheHarbour" style={{'justifySelf':'flex-start'}}>                               
                            <button className='txt__primary bg__trans' style={{'padding': '0 0'}} type='button'>Read More</button>
                        </a>
                    </div>
                    <div className="about__us__content wrapper grid gap-small">
                        <h3 className="fw500 capitalize txt__primary">
                            Our Mission
                        </h3>
                        <p className="normal">
                            “To ensure that girls, women and parents get informed, trained and educated about the welfare, better standards of living and human rights of every girl child for self-sustainability and a great bright future for every girl child socially, economically, psychologically and spiritually. 
                        </p>
                    </div>
                    
                    <div className="about__us__content wrapper grid gap-small">
                        <h3 className="fw500 capitalize txt__primary">
                            Our Vision
                        </h3>
                        <p className="normal italic">
                            “To provide relief and empowerment to the vulnerable girl child”
                        </p>
                    </div>
                </div>
                <img
                    src="https://images.unsplash.com/photo-1509099836639-18ba1795216d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YWZyaWNhbiUyMGdpcmxzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                    alt=""
                    className="about__us__img"
                />
            </section>
        </>
    );
}
 
export default AboutUs;