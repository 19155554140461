import PropTypes from 'prop-types';

const SectionHeading = ({title}) => {
    return (
        <div className="section__heading container wdth80 txt__primary pd-block-2">
            <h3 className="fw500">{title}</h3>
        </div>
    );
}
 
export default SectionHeading;

SectionHeading.propTypes = {
    title: PropTypes.string.isRequired,
}