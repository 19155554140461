import PropTypes from 'prop-types';

const BasicNeedCard = ({title, description, icon}) => {
    return (
        <>
            <article className="basic_needs_card wrapper grid txt__secondary">
                <div className="icon wrapper grid content-centered txt__primary">
                    {icon}
                </div>
                <div className="card__content wrapper grid gap-small">
                    <h3 className="fw500 txt__primary capitalize">{title}</h3>
                    <p className="fw300">{description}</p>
                </div>
            </article>
        </>
    );
}
 
export default BasicNeedCard;

BasicNeedCard.propTypes = {
    title : PropTypes.string.isRequired,
    description : PropTypes.string.isRequired,
}