import Button from '../utils/Button';
import BasicNeedCard from './BasicNeedCard';

import { FaMoneyBill, FaUserGraduate, FaFemale } from 'react-icons/fa';
import { MdCleanHands, MdOutlineHealthAndSafety, MdOutlineSavings } from "react-icons/md";

const PageStarter = () => {
    return (
        <>
            <section className="starter container wdth90 wdth100-tb pd-inline-3-mb grid">
                <div className="starter__infro grid pd-2 pd-inline-2-mb pd-block-2-mb">
                    <h1 className="slogan txt__primary fw900 capitalize">
                        Providing Relief and empowerment to the vunerable girl child.
                    </h1>
                    <h3 className="slogan__sub txt__secondary normal capitalize">
                        Solving the problems faced by girls, women and children across Uganda
                    </h3>
                    <Button
                        classes={'btn bg__primary txt__light flex gap-small round align-items-center justify-items-center'}
                        value='Join The Cause'
                    />
                </div>
                <div className="img__area wrapper pd-1 grid content-centered">
                    <img
                        src="https://images.unsplash.com/photo-1617056239820-8ce90ba48193?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGFmcmljYW4lMjBnaXJsc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                        alt=""
                    />
                </div>
            </section>
            <section className="basic__needs container grid gap1 gap2-mb wdth80 wdth90-mb mg-block-3">
                <BasicNeedCard title={'Clean Water'} description={'Providing clean and reliable water.'} icon={<MdCleanHands />}/>
                <BasicNeedCard title={'Education'} description={'Compulsory staying in school for girl education.'} icon={<FaUserGraduate />}/>
                <BasicNeedCard title={'Proper Health'} description={'Improved Healthy services through advanced female Health care.'} icon={<MdOutlineHealthAndSafety />}/>
                <BasicNeedCard title={'Saving credit schemes'} description={'Income generating projects and saving credit schemes.'} icon={<MdOutlineSavings />}/>
                <BasicNeedCard title={'Financial assistance'} description={'To seek grants and credit facilities from the government.'} icon={<FaMoneyBill />}/>
                <BasicNeedCard title={'Unity among women'} description={'To mobilize girls, women and parents to strengthen the unity among females.'} icon={<FaFemale />}/>
            </section>
        </>
    );
}
 
export default PageStarter;