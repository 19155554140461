import Header from './components/utils/Header';
import Footer from './components/utils/Footer';
import LandingPage from './components/pages/LandingPage';

import Causes from './components/causes/Causes';
import Events from './components/events/Events';
import Article from './components/utils/Article';

import { useState, useEffect } from 'react';
import { SpinnerDotted } from 'spinners-react';

import { AppcontextProvider, useRoutesContext } from './components/AppHooks/AppContext';

import {
  Routes ,
  Route
} from "react-router-dom";

const App = () => {

  const [ showLoader, setShowLoader ] = useState(true);
  const routes = useRoutesContext();

  useEffect(() => {

    return (
      setTimeout(() => {
        setShowLoader(false)
      }, 1500)
    )

  }, [])

  return (
    <AppcontextProvider>
      <div className={`loader ${!showLoader ? 'hidden' : ''}`}>
          <SpinnerDotted сolor={'rgb(38, 115, 87)'} size={100} enabled={true} />
      </div>
      <div style={{display : `${showLoader ? 'none' : 'block'}`}}>
        <Header routes={routes}/>
        <main>
          <Routes>
                <Route path={routes.home} exact element={<LandingPage />} />
                <Route path={routes.causes} exact element={<Causes />} >
                  <Route path = ':id/:title' element={<Article />}/>
                </Route>
                <Route path={routes.events} exact element={<Events />} >
                  <Route path = ':id/:title' element={<Article />}/>
                </Route>
          </Routes>
        </main>
        <Footer />
      </div>
    </AppcontextProvider>
  );
}
 
export default App;