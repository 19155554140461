// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useRoutesContext } from '../AppHooks/AppContext';

const CauseCard = ({cause}) => {

    const routes = useRoutesContext();

    return (
        <>
            <article className="cause__card wrapper grid gap1 bg__primary txt__light pd-2">
                <Link
                    to={`${routes.causes}/${cause["id"]}/${cause["title"]}`}
                    className="txt__light"
                    >
                    <h3
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        className="title fw500 capitalize"
                    >
                        {cause["title"]}
                    </h3>
                </Link>
                <p className="fw300">
                    {cause["description"]} ...
                </p>
            </article>
        </>
    );
}
 
export default CauseCard;

// CauseCard.propTypes = {
//     id : PropTypes.string.isRequired,
//     title : PropTypes.string.isRequired,
//     description : PropTypes.string.isRequired,
// }