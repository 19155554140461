import CauseCard from '../causes/CauseCard';
import ViewMore from '../utils/ViewMore';
import SectionHeading from '../utils/SectionHeading';
import Article from '../utils/Article';

import { useLocation } from 'react-router-dom';

import {
    Outlet
} from "react-router-dom";

const Causes = () => {

    const location = useLocation();

    const causes = [
        {
            "id": 0,
            "title": "Advocacy for Girls’ stay in school.",
            "description": "Throughout African history, the girl child has always been underprivileged and is always considered the minority group due to various reasons one of which culture."
        },
        {
            "id": 1,
            "title": "Advocacy for awareness of expression and other human rights",
            "description": "The Ugandan Constitution expressively provides for freedom of expression and other human rights to every individual in the country"
        },
        {
            "id": 2,
            "title": "Empowering the girl child.",
            "description": "The state of life that various girls in villages, slums, and even some developed areas live in, is really miserable and saddening. Due to poverty, most girls have been forced into early marriages, early teenage pregnancies and much worse situations."
        },
        {
            "id": 3,
            "title": "Assisting Women Through Farming Projects ",
            "description": "The foundation aims at starting up farming projects like coffee farming, subsistence farming of crops like maize, beans, ground nuts, cassava, carrots, irish potatoes, bananas (matooke) "
        }
    ]

    return (
        <>
            { location.pathname !== '/' && <div className="block-sm" style={{height : '7rem'}}></div>}
            <Outlet>
                <Article />       
            </Outlet>
            <SectionHeading title='Our Causes'/>
            <section className="causes container wdth80 wdth90-mb grid gap2 gap1-mb">
                {causes.map(cause => <CauseCard
                    key={cause["id"]}
                    cause={cause}
                />)}
                
            </section>
            <ViewMore />
        </>
    );
}
 
export default Causes;