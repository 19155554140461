import { useRef, useEffect } from 'react';

// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useRoutesContext } from '../AppHooks/AppContext';

const EventCard = ({event}) => {

    const routes = useRoutesContext();

    const articleRef = useRef();

    const displayContentOnMobile = () => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('inview')
                }
                else
                {
                    entry.target.classList.remove('inview')
                }
            })
        }, {
            root : null,
            threshold: .7,
        })

        observer.observe(articleRef.current);

    }

    useEffect(() => {

        displayContentOnMobile();

    }, [])

    return (
        <>
            <Link
                to={`${routes.events}/${event["id"]}/${event["title"]}`}
                className="txt__light"
                >
                <article
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    className="event__card wrapper grid stacked" ref={articleRef}
                >
                    <img src={event["images"][0]} alt={event["title"]} />
                    <div className="card__content wrapper grid txt__light pd-1">
                            <h4
                                className="card__title fw500 capitalize"
                            >
                                {event["title"]}
                            </h4>
                        <p className="fw300">Date: {event["duration"]}</p>
                    </div>
                </article>
            </Link>
        </>
    );
}
 
export default EventCard;

// EventCard.propTypes = {
//     id : PropTypes.string.isRequired,
//     title : PropTypes.string.isRequired,
//     imgUrl : PropTypes.string.isRequired,
//     date : PropTypes.string.isRequired,
// }