import Button from '../utils/Button';

const ViewMore = () => {
    return (
        <>
            <div className="lower__btn container grid content-centered mg-block-1">
                <Button
                    classes='btn txt__primary outlined round'
                    value='View More'
                />
            </div>
        </>
    );
}
 
export default ViewMore;