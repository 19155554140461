import { useLocation, useParams } from 'react-router-dom';


import Button from './Button';

const Article = () => {

    const { id } = useParams()

    const location = useLocation();

    const causes = [
        {
            "id": 0,
            "title": "Advocacy for Girls’ stay in school.",
            "description": "Throughout African history, the girl child has always been underprivileged and is always considered the minority group due to various reasons one of which culture.  Travelling through the various different parts of Uganda, both in rural and urban areas, one quickly realizes the fact that so many girls are out of school.  Our mission looks at making the society an equal livable place to both gender. We want to give confidence to the girl child through advocating for their stay in school and create a better society where they too can fit into spaces and be heard. We look forward to fighting against the various factors that keep girls out of school like early teenage pregnancies, early forced marriages, cultural benefits from the girl child, like bride price to the parents, poverty, domestic violence, child labour and provision of sanitary towels. In Uganda, various girls miss school due to menstrual periods issues. Since they lack sanitary towels, some use soil, cow dung, leaves as sanitary towels. Therefore we look forward to providing a solution to each and every problem so as to keep the girl child in school and empower them realize their worth and take up spaces in the world. Help us keep the girl child in school through donating towards our cause. "
        },
        {
            "id": 1,
            "title": "Advocacy for awareness of expression and other human rights",
            "description": "The Ugandan Constitution expressively provides for freedom of expression and other human rights to every individual in the country. However it is very unfortunate that since time immemorial, the girl child has been condemned and called various names when she speaks out on any matter. This has caused so many to end up in bad situations like unwanted early marriages, teenage pregnancies, domestic violations, battery, sexual harassment and many more scenarios that degrade the rights of the girl child. We at She Harbour Foundation seek to put an end to this through educating as many girls as we can about their rights and that they are entitled to an opinion. Through teachings girls are to be sensitized about their role in the community, they are to be trained to stand for their rights. They shall be sensitized about the law and policies against gender based violence, inequality, women emancipation, sexual harassment among others. We aim at by all means, providing relief to the many violated girls, the silent and the active ones, by giving them their voices and space to share their stories and flourish in as many ways as they as can, advocate for all their rights; for we believe if a man can, a woman too can. We therefore seek for your financial support so that we see this cause through."
        },
        {
            "id": 2,
            "title": "Empowering the girl child.",
            "description": "The state of life that various girls in villages, slums, and even some developed areas live in, is really miserable and saddening. Due to poverty, most girls have been forced into early marriages, early teenage pregnancies and much worse situations. These girls have been undermined by society and due to patriarchy; they don’t have a voice in communities. We stand with them in encouragement so that we offer a new perspective about life and how they can go through difficult situations. She Harbour Foundation seeks to put an end to this through empowering so many of these girls through holding fellowships, camps, hands-on project training so as to empower and create a self-reliant, confident and strong women for tomorrow who can stand up on their own, take up spaces and live a recommendable life. Through fellowships, we shall hold several facilitations on how to succeed in life, stand up for one self and others through all circumstance. We intend to hold camps to further energize the girl child on how to go about life, counseling sessions, provision of support and a push to all corners of what the world can offer. We shall hold hand-on project trainings like teaching girls and women how to make Re-usable sanitary towels which will resolve the issue of using unhygienic materials like soil, leaves etc. we shall make liquid cleaning soap, candles, art, farming, sewing and much more. This is all to empower women economically through giving them opportunities to start-up businesses and fight poverty and being self-reliant financially. Achieve with us this goal through donating to our cause."
        },
        {
            "id": 3,
            "title": "Assisting Women Through Farming Projects ",
            "description": "The foundation aims at starting up farming projects like coffee farming, subsistence farming of crops like maize, beans, ground nuts, cassava, carrots, irish potatoes, bananas (matooke) and other food crops so as to enhance the daily daily living of women. Through acquiring land where various women can carry out farming activities, several women founded households will be able to have daily food on their houses to feed on, they will be able to have crops like coffee to sell on the market and earn something to pay the children’s school fees, buy clothing and other family needs."
        }
    ]

    const events = [
        {
            id: 0,
            title: 'Northern Uganda Outreach',
            description : 'An outreach to help struggling mothers was held in Nothern Uganda September 2021 where the foundation was astonished at the poor living conditions most mothers in the region were living in. Most of these ladies aired out problems like lack of food, basic needs for the children and alcoholic husbands. We were able to reach out to some of the women in the area with food, pads, counseling sessions and promised to do more ', 
            duration : 'September 2021',
            locations: ['Nothern Uganda'],
            images : [
                `${process.env.PUBLIC_URL}/events/northern/1.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/2.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/3.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/4.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/5.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/6.jpg`,
            ]
        },
        {
            id: 1,
            title: 'Central Uganda (School) Outreach',
            description : 'New styles Secondary School Kawaala-Kampala is a partly government aided school within Kampala City and there goes so many students from poor households. Among other things, so many of the female students lack day to day needs that a girl child needs like sanitary towels, books, pens, clothes, shoes and much more. We held a female student meet on the 8th March,2022 on International Women’s day and had talks with the female students at this school. So many of them aired problems of school fees, sanitary towels, painful menstrual periods and so on. Counseling and encouraging sessions were held with the students and the foundation is keeping in touch with so many of these students', 
            duration : '8th March,2022',
            locations: ['Central Uganda', 'Kampala', 'Kasubi'],
            images : [
                `${process.env.PUBLIC_URL}/events/central/1.jpg`,
                `${process.env.PUBLIC_URL}/events/central/2.jpg`,
                `${process.env.PUBLIC_URL}/events/central/3.jpg`,
                `${process.env.PUBLIC_URL}/events/central/4.jpg`,
                `${process.env.PUBLIC_URL}/events/central/5.jpg`,
                `${process.env.PUBLIC_URL}/events/central/6.jpg`,
                `${process.env.PUBLIC_URL}/events/central/7.jpg`,
            ]
        },
        {
            id: 2,
            title: 'Western Uganda Outreach',
            description : 'The foundation in February 2021 carried out an outreach in Western Uganda and the area was characterized with female school dropouts, female headed households, early marriages, poor living conditions, lack of enough food in so many households, domestic violence and so many other issues. We were able to offer guidance and counseling to the bitter violated women, encouraged the girl children to go back to school and offered some food, pads, and more', 
            duration : 'February 2021',
            locations: ['Western Uganda'],
            images : [
                `${process.env.PUBLIC_URL}/events/western/1.jpg`,
                `${process.env.PUBLIC_URL}/events/western/2.jpg`,
                `${process.env.PUBLIC_URL}/events/western/3.jpg`,
                `${process.env.PUBLIC_URL}/events/western/4.jpg`,
                `${process.env.PUBLIC_URL}/events/western/5.jpg`,
            ]
        },
    ]

    return (
        location.pathname.includes("causes") ? 
        <>
            {/* <img className='article__img' src={images[0]} alt="" /> */}
            <section className="article__content container wdth80 grid gap3">
                <div className="article__description wrapper grid gap1">
                    <h2 className="fw500 txt__primary">{causes[id]["title"]}</h2>
                    <p className="normal txt__secondary">
                        {causes[id]["description"]}
                    </p>
                    <Button
                        classes='btn bg__primary txt__light round'
                        value='Sponsor'
                    />
                </div>
            </section>
        </>
        :
        <>
            <img className='article__img' src={events[id]["images"][0]} alt="" />
            <section className="article__content container wdth80 grid gap3">
                <div className="article__description wrapper grid gap1">
                    <h3 className="fw500 txt__primary">{events[id]["title"]}</h3>
                    <p className="normal txt__secondary">
                    {events[id]["description"]}
                    </p>
                    <Button
                        classes='btn bg__primary txt__light round'
                        value='Sponsor'
                    />
                </div>
                <aside className="article__side__infor grid gap2">
                    <table className='txt__secondary'>
                        <tbody>                            
                            {
                                events[id]["duration"] &&
                                <tr>
                                    <td>Duration:</td>
                                    <td>{events[id]["duration"]}</td>
                                </tr>
                            }
                            <tr>
                                <td>location:</td>
                                <td>
                                    {
                                        events[id]["locations"].map(location => {
                                            return (
                                                <span key={`${events[id]["title"]}-${location}`}>
                                                    {location}
                                                    {(events[id]["locations"].indexOf(location) < events[id]["locations"].length - 1) ? <>, </> :<></>}
                                                </span>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                            {/* <tr>
                                <td>Target:</td>
                                <td>{`$${Target}`}</td>
                            </tr>
                            <tr>
                                <td>Raised:</td>
                                <td>{`$${Raised}`}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    <div className="extra__img wrapper wrapper grid">
                        {
                            events[id]["images"].slice(1,).map(
                                imgUrl => {
                                    return (
                                        <img key={imgUrl} src={imgUrl} alt={events[id]["title"]} />
                                    )
                                }
                            )
                        }
                    </div>
                </aside>
            </section>
        </>
    );
}
 
export default Article;