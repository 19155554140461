import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {

    const [ acceptedCookies, setAcceptedCookies ] = useState(true);

    function setCookie() {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 31536000000;
        now.setTime(expireTime);
        const cname = 'allowed-cookies';
        document.cookie = `${cname}=${true};SameSite=None;Secure;expires=+${now.toUTCString()}+;path=/`
        setAcceptedCookies(true)
      }
      
      function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

    useEffect(() => {

        let allowedCookies = getCookie("allowed-cookies");
        if (allowedCookies) {
            setAcceptedCookies(true);
        } else {
            setAcceptedCookies(false);
        }

        setTimeout(() => {
            setAcceptedCookies(false)
        }, 30000)

    }, [])

    return (
        <>
        <div className={`cookie wrapper bg__primary box-shadow-black ${acceptedCookies ? 'hide' : ''}`}>
            <p className='txt__light'>We use cookies to provide our services and for analytics and marketing. To find out more about our use of cookies, please see our Privacy Policy and Cookie and Tracking Notice. By continuing to browse our website, you agree to our use of cookies.</p>
            <button className="bold" onClick={() => setCookie()}>X</button>
        </div>
            <footer className='bg__secondary'>
                <section className="container wdth80 pd-block-3 grid gap2 txt__light">
                    <div className="upper__footer__area grid content-centered gap2-mb">
                        <div className="newsletter wrapper grid gap1">
                            <h3 className="fw500 capitalize">
                                Subscribe to our newsletter
                            </h3>
                            <h5 className="fw300 capitalize">
                                Receive timely Updates about Our Events
                            </h5>
                            <form className="grid gap-small" action="" method="post">
                                <input className="txt__Secondary" type="text" name="email" id="email" placeholder="Enter you email..."/>
                                <input className="bg__accent txt__light" type="submit" value='Subcribe'/>
                            </form>
                        </div>
                        <div className="socials grid gap1 content-centered">
                            <h4 className="fw500 capitalize">
                                Follow us via:
                            </h4>
                            <div className="social__icons flex gap1">
                                <FaFacebookF />
                                <FaInstagram />
                                <FaTwitter />
                            </div>
                        </div>
                    </div>
                    <h6 className="copyright fw400 capitalize txt__light">
                    Copyright &copy; {(new Date().getFullYear())}, All rights reserved. Property Of SheHarbour Foundation
                    </h6>
                </section>
            </footer>
        </>
    );
}
 
export default Footer;
