import { useRef, useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { MdOutlineDoubleArrow } from "react-icons/md";
import Button from './Button';
import { Link } from 'react-router-dom';

import { useRoutesContext } from '../AppHooks/AppContext';

const Header = () => {

    const routes = useRoutesContext();
  
    const headerRef = useRef();
    const logo = useRef();

    const [showTabs, setShowTabs ] = useState(false);

    const observeHeader = () => {
        const target = headerRef.current;
        if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
            logo.current.style.display = 'none';
        }
        else
        {
            logo.current.style.display = 'block';
        }
        if (window.innerWidth > 850) {            
            if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
                target.style.position = 'fixed';
                target.style.background = 'white';
                target.style.boxShadow = '0 1px 3px rgba(0, 0, 0, 0.205)';
            }
            else
            {
                target.style.position = 'absolute';
                target.style.background = 'transparent';
                target.style.boxShadow = 'none';
            }
        }
    }

    const toggleTabs = () => {
        setShowTabs(!showTabs);
    }

    useEffect(() => {
        document.addEventListener('scroll', () => observeHeader())

        return (
            document.removeEventListener('scroll', () => observeHeader())
        )
    }, [])

    return (
        <>
            <header ref={headerRef}>
                <nav className="container wdth90 flex pd-block-sm">
                    <div className='logo_tabs_area'>
                        <Link
                            to={`/`}
                            className="txt__light"
                        >
                            <div className="logo__area wrapper flex gap1">
                                <img ref={logo} src={process.env.PUBLIC_URL + '/sheharbour_logo.png'} alt="logo" />
                                <h3 className="lower txt__secondary">SheHarbour Foundation</h3>
                            </div>
                        </Link>
                        <div className="tabs desktop-tabs">
                            <Link
                                to={routes.home}
                                className="txt__secondary"
                                onClick={toggleTabs}
                            >
                                Home
                            </Link>
                            <Link
                                to={routes.events}
                                className="txt__secondary"
                                onClick={toggleTabs}
                            >
                                Events
                            </Link>
                            <Link
                                to={routes.causes}
                                className="txt__secondary"
                                onClick={toggleTabs}
                            >
                                Causes
                            </Link>
                            {/* <Link
                                to={routes.home}
                                className="txt__secondary"
                                onClick={toggleTabs}
                            >
                                Admin 
                            </Link> */}
                            <Link
                                to={routes.home}
                                className="txt__light bg__primary"
                                onClick={toggleTabs}
                            >
                                Donate
                            </Link>
                        </div>
                    </div>
                    <Button
                        classes={'outlined txt__secondary content-centered mobile_tab_activaor'}
                        onButtonClick={toggleTabs}
                    >
                        { showTabs ? <MdOutlineDoubleArrow /> : <FaBars /> }
                    </Button>
                </nav>
                <div className={`tabs grid ${!showTabs ? 'hidden' : ''}`}>
                    <Link
                        to={routes.home}
                        className="txt__secondary"
                        onClick={toggleTabs}
                    >
                        Home
                    </Link>
                    <Link
                        to={routes.events}
                        className="txt__secondary"
                        onClick={toggleTabs}
                    >
                        Events
                    </Link>
                    <Link
                        to={routes.causes}
                        className="txt__secondary"
                        onClick={toggleTabs}
                    >
                        Causes
                    </Link>
                    {/* <Link
                        to={routes.home}
                        className="txt__secondary"
                        onClick={toggleTabs}
                    >
                        Admin 
                    </Link> */}
                    <Link
                        to={routes.home}
                        className="txt__light bg__primary"
                        onClick={toggleTabs}
                    >
                        Donate
                    </Link>
                </div>
            </header>
        </>
    );
}
 
export default Header;