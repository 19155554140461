import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Button = ({classes, value, onButtonClick, children}) => {

    const buttonRef = useRef();
    const [buttonAppearance, setButtonAppearance] = useState({background: '', color: ''});

    const getButtonColorCode = (color) => {
        let colors = {
            primary : '38 115 87',
            secondary : '56 76 67',
            tertiary : '154 176 166',
            accent : '18 109 153',
            dark : '0 0 0',
            light : '255 255 255',
        }
        return colors[color.split('__')[1]];
    }

    const onHover = () => {
        let target = buttonRef.current;

        let color = '';
        if (buttonAppearance.background === 'outlined' || buttonAppearance.background === 'bg__trans')
        {
            color =  buttonAppearance.color;
        }
        else
        {
            color =  buttonAppearance.background;
        }


        let hoverColorCode = getButtonColorCode(color);

        if (buttonAppearance.background.includes('outlined'))
        {
            target.style.background = `rgb(${hoverColorCode})`;
            target.style.border = `2px solid rgb(${hoverColorCode})`;
            target.style.color = `white`;
        }
        else if(buttonAppearance.background === 'bg__trans')
        {
            target.style.textDecoration = 'underline';
        }
        else
        {
            target.style.background = 'transparent';
            target.style.border = `2px solid rgb(${hoverColorCode})`;
            target.style.color = `rgb(${hoverColorCode}`;
        }
    }
    
    const onLeave = () => {
        let target = buttonRef.current;
        if (buttonAppearance.background.includes('outlined'))
        {
            target.style.background = `transparent`;
            target.style.border = `2px solid rgb(${getButtonColorCode(buttonAppearance.color)})`;
            target.style.color = `rgb(${getButtonColorCode(buttonAppearance.color)})`;
        }
        else if(buttonAppearance.background === 'bg__trans')
        {
            target.style.textDecoration = 'none';
        }
        else
        {
            target.style.background = `rgb(${getButtonColorCode(buttonAppearance.background)})`;
            target.style.border = `2px solid transparent`;
            target.style.color = `rgb(${getButtonColorCode(buttonAppearance.color)})`;
        }
    }

    useEffect(() => {
        const btnClasses = classes.split(' ');
        if (btnClasses.includes('outlined')) {
            const color = getButtonColorCode(btnClasses.filter(btnClass => btnClass.includes('txt'))[0])
            buttonRef.current.style.border = `2px solid rgb(${color})`;
        }

        // set Appearance
        const btnBackground = btnClasses.filter(btnClass => btnClass.includes('bg'))[0] || btnClasses.filter(btnClass => btnClass.includes('outlined'))[0] || 'bg__trans'
        const btnColor = btnClasses.filter(btnClass => btnClass.includes('txt'))[0]

        setButtonAppearance({
            background : btnBackground,
            color : btnColor
        })

    }, [classes])

    return (
        <>
            <button
                ref={buttonRef}
                className={classes}
                onClick={onButtonClick}
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
            >
                {children}
                {value}
            </button>
        </>
    );
}
 
export default Button;

Button.propTypes = {
    classes : PropTypes.string.isRequired,
    value : PropTypes.string,
    onButtonClick : PropTypes.func
}