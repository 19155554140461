import React, { useContext } from "react";

const RoutesContext = React.createContext({
    home : '/',
    about : '/about-us',
    events : '/events',
    causes : '/causes',
});

export const useRoutesContext = () => useContext(RoutesContext);

export const AppcontextProvider = ({children}) => {

    const routes = {
        home : '/',
        about : '/about-us',
        events : '/events',
        causes : '/causes',
    };

    return  (
        <RoutesContext.Provider value={routes}>
            {children}
        </RoutesContext.Provider>
    )
}