import PageStarter from '../pageComponents/PageStarter';
import Causes from '../causes/Causes';
import Events from '../events/Events';
import AboutUs from '../aboutUs/AboutUs';

const LandingPage = () => {
    return (
        <>
            <PageStarter />
            <AboutUs />
            <Causes />
            <Events />
        </>
    );
}
 
export default LandingPage;