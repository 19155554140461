import EventCard from './EventsCard';
import ViewMore from '../utils/ViewMore';
import SectionHeading from '../utils/SectionHeading';
import Article from '../utils/Article';

import { useLocation } from 'react-router-dom';

import {
    Outlet
} from "react-router-dom";



const Events = () => {
    const location = useLocation();

   

    const events = [
        {
            id: 0,
            title: 'Northern Uganda Outreach',
            description : 'An outreach to help struggling mothers was held in Nothern Uganda September 2021 where the foundation was astonished at the poor living conditions most mothers in the region were living in. Most of these ladies aired out problems like lack of food, basic needs for the children and alcoholic husbands. We were able to reach out to some of the women in the area with food, pads, counseling sessions and promised to do more ', 
            duration : 'September 2021',
            locations: ['Nothern Uganda'],
            images : [
                `${process.env.PUBLIC_URL}/events/northern/1.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/2.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/3.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/4.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/5.jpg`,
                `${process.env.PUBLIC_URL}/events/northern/6.jpg`,
            ]
        },
        {
            id: 1,
            title: 'Central Uganda (School) Outreach',
            description : 'New styles Secondary School Kawaala-Kampala is a partly government aided school within Kampala City and there goes so many students from poor households. Among other things, so many of the female students lack day to day needs that a girl child needs like sanitary towels, books, pens, clothes, shoes and much more. We held a female student meet on the 8th March,2022 on International Women’s day and had talks with the female students at this school. So many of them aired problems of school fees, sanitary towels, painful menstrual periods and so on. Counseling and encouraging sessions were held with the students and the foundation is keeping in touch with so many of these students', 
            duration : '8th March,2022',
            locations: ['Central Uganda', 'Kampala', 'Kasubi'],
            images : [
                `${process.env.PUBLIC_URL}/events/central/1.jpg`,
                `${process.env.PUBLIC_URL}/events/central/2.jpg`,
                `${process.env.PUBLIC_URL}/events/central/3.jpg`,
                `${process.env.PUBLIC_URL}/events/central/4.jpg`,
                `${process.env.PUBLIC_URL}/events/central/5.jpg`,
                `${process.env.PUBLIC_URL}/events/central/6.jpg`,
                `${process.env.PUBLIC_URL}/events/central/7.jpg`,
            ]
        },
        {
            id: 2,
            title: 'Western Uganda Outreach',
            description : 'The foundation in February 2021 carried out an outreach in Western Uganda and the area was characterized with female school dropouts, female headed households, early marriages, poor living conditions, lack of enough food in so many households, domestic violence and so many other issues. We were able to offer guidance and counseling to the bitter violated women, encouraged the girl children to go back to school and offered some food, pads, and more', 
            duration : 'February 2021',
            locations: ['Western Uganda'],
            images : [
                `${process.env.PUBLIC_URL}/events/western/1.jpg`,
                `${process.env.PUBLIC_URL}/events/western/2.jpg`,
                `${process.env.PUBLIC_URL}/events/western/3.jpg`,
                `${process.env.PUBLIC_URL}/events/western/4.jpg`,
                `${process.env.PUBLIC_URL}/events/western/5.jpg`,
            ]
        },
    ]
    
    return (
        <>
            { location.pathname !== '/' && <div className="block-sm" style={{height : '7rem'}}></div>}
            <Outlet>
                <Article />       
            </Outlet>
            <SectionHeading title='Events'/>
            <section className="events container grid wdth100">
                {
                    events.map(event => <EventCard
                        key={event["id"]}
                        event={event}
                    />)
                }
            </section>
            <ViewMore />
        </>
    );
}
 
export default Events;